import { Component, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

@NgModule({
    imports: [RouterModule.forChild([
     { path: 'usuarios', data: { breadcrumb: 'Usuarios' }, loadChildren: () => import('./components/users-crud/user-crud.module').then(m => m.UserCrudModule) },
     { path: 'contratos', data: { breadcrumb: 'Contratos' }, loadChildren: () => import('./components/contratos-crud/contratos-crud.module').then(m => m.UserCrudModule) },

    ])],
    exports: [RouterModule]
})
export class AdministrativoRoutingModule { }
