<div class="surface-ground flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden">
    <div class="flex flex-column align-items-center justify-content-center">
        <div
            style="border-radius:56px; padding:0.3rem; background: linear-gradient(180deg, rgb(112, 187, 112) 10%, rgba(33, 150, 243, 0) 30%);">
            <div class="w-full surface-card py-8 px-5 sm:px-8" style="border-radius:53px">
                <div class="text-center mb-5">
                    <img src="assets/layout/images/logo-branca.png" alt="Logo Visual Solution" class="logo">
                </div>

                <div class="text-center mb-5">
                    <span class="text-600 font-medium">Faça o login para continuar</span>
                </div>
                <form [formGroup]="myForm" (ngSubmit)="onSubmit()">
                    <div class="form-group">
                        <label for="Email" class="block text-900 text-xl font-medium mb-2">Email</label>
                        <input id="user" formControlName="email" pInputText class="w-full mb-5" style="padding:1rem">
                    </div>
                    <div class="form-group">
                        <label for="senha" class="block text-900 font-medium text-xl mb-2">Senha</label>
                        <p-password id="pass" [feedback]="false" formControlName="password" [toggleMask]="true"
                            styleClass="mb-5 w-full" inputStyleClass="w-full p-3"></p-password>
                    </div>


                    <div class="flex align-items-center justify-content-between mb-5 gap-5">
                        <button type="submit" pButton pRipple label="Entrar" class="w-full p-3 text-xl"></button>
                    </div>
                </form>

            </div>
        </div>
    </div>
</div>

<p-toast></p-toast>
