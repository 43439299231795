import { Component, ElementRef, ViewChild, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { LayoutService } from './service/app.layout.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html',
    styleUrls: ['./app.topbar.component.scss'],
})
export class AppTopBarComponent implements OnInit {
    items!: MenuItem[];
    dialogSair: boolean = false;
    userDados: any = {}; // Inicializa como um objeto vazio para evitar erros de acesso
    grupoPetrobras: boolean = false;
    @ViewChild('menubutton') menuButton!: ElementRef;
    @ViewChild('topbarmenubutton') topbarMenuButton!: ElementRef;
    @ViewChild('topbarmenu') menu!: ElementRef;

    constructor(public layoutService: LayoutService, private router: Router) {}

    ngOnInit() {
        this.recuperarDadosUsuario();
    }

    // Função para recuperar dados do localStorage
    recuperarDadosUsuario() {
        const grupoJSON = localStorage.getItem('dadosUser');

        if (grupoJSON) {
            try {
                this.userDados = JSON.parse(grupoJSON); // Faz o parsing uma única vez
                this.verificarGrupo();
            } catch (error) {
                console.error('Erro ao parsear dados do usuário:', error);
                this.userDados = {};
            }
        }
    }

    // Verificar grupo do usuário e definir flags
    verificarGrupo() {
        if (this.userDados && Array.isArray(this.userDados)) {
            const grupo = this.userDados[0]?.grupo; // Verifica se userDados é uma array e acessa o grupo
            if (
                grupo === 'admPetrobrasEquipamentos' ||
                grupo === 'clintePetrobrasEquipamentos'
            ) {
                this.grupoPetrobras = true;
            }
        } else {
            console.warn('userDados não é uma array válida.');
        }
    }

    // Função para remover itens específicos do localStorage e realizar logout
    sair() {
        try {
            // Remover dados do localStorage
            localStorage.removeItem('dadosUser');
            localStorage.removeItem('grupo');

            // Chamar a função de logout do layoutService, se houver lógica adicional
            this.layoutService.logoutFirebase();
        } catch (error) {
            console.error('Erro ao sair da aplicação:', error);
            this.router.navigate(['/login']); // Redirecionar mesmo que haja erro
        }
    }
}
