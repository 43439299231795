import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { Observable, map } from 'rxjs';
import { AngularFirestore } from '@angular/fire/compat/firestore';

type NewType = boolean;

@Injectable()
export class AuthService {
    mostarMenuEmitter = new EventEmitter();
    usuarioAutenticado: boolean = false;

    constructor(
        private router: Router,
        private angularFirestore: AngularFirestore
    ) {}

    fazerLogin(email: string, password: string) {
        const auth = getAuth();
        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                const user = userCredential.user;
                this.router.navigate(['/']);
                this.mostarMenuEmitter.emit(true);
                this.usuarioAutenticado = true;
            })
            .catch((error) => {
                this.mostarMenuEmitter.emit(false);
                this.usuarioAutenticado = false;
            });
    }

    conferirUsuarioLogado(autenticacao: boolean) {
        if (autenticacao) {
            this.mostarMenuEmitter.emit(true);
            this.usuarioAutenticado = true;
            this.router.navigate(['/']);
        }
    }

    usuarioEstaAutenticado() {
        return this.usuarioAutenticado;
    }

    getEmail() {
        const auth = getAuth();
        return auth.currentUser?.email;
    }

    getUser(email: string): Observable<any[]> {
        return this.angularFirestore
            .collection('users', (ref) => ref.where('email', '==', email))
            .snapshotChanges()
            .pipe(
                map((actions) => {
                    return actions.map((a) => {
                        const data = a.payload.doc.data() as any;
                        const id = a.payload.doc.id;
                        return { id, ...data };
                    });
                })
            );
    }
}
