<div class="layout-topbar">
    <button
        #menubutton
        class="p-link layout-menu-button layout-topbar-button"
        (click)="layoutService.onMenuToggle()"
    >
        <i class="pi pi-bars"></i>
    </button>

    <div class="imagens-top">
        <div class="left">
            <a class="layout-topbar-logo" routerLink="" *ngIf="grupoPetrobras">
                <img src="/assets/layout/images/cetest.png" alt="CETEST logo" />
            </a>
        </div>

        <div class="center">
            <a class="layout-topbar-logo" routerLink="">
                <img
                    src="/assets/layout/images/logo-verde-min.png"
                    alt="Visual Solutions logo"
                />
                <span>Visual Solutions</span>
            </a>
        </div>

        <div class="right">
            <a class="petrobras-logo-container" *ngIf="grupoPetrobras">
                <img
                    src="assets/layout/images/petrobras.png"
                    alt="Petrobras logo"
                    style="width: 140px; height: 140px"
                />
            </a>
        </div>
    </div>

    <button class="p-link layout-topbar-button" (click)="dialogSair = true">
        <i class="pi pi-power-off"></i>
        <span>Sair</span>
    </button>
</div>

<p-dialog
    [(visible)]="dialogSair"
    [style]="{ width: '400px' }"
    header="Deseja Realmente Sair?"
    [modal]="true"
>
    <p-footer>
        <button
            type="button"
            pButton
            icon="pi pi-times"
            label="Não"
            (click)="dialogSair = false"
        ></button>
        <button
            type="button"
            pButton
            icon="pi pi-check"
            label="Sim"
            (click)="sair()"
        ></button>
    </p-footer>
</p-dialog>
