import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { LayoutService } from './service/app.layout.service';
import { AuthService } from '../auth/auth.service';

interface MenuItem {
    label?: string;
    icon?: string;
    routerLink?: string | string[];
    items?: MenuItem[];
    separator?: boolean;
}
interface DataItem {
    code: string;
    name: string;
    contratoPai: string | null;
}

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html',
})
export class AppMenuComponent implements OnInit {
    model: MenuItem[] = [];
    userDados: any = {}; // Inicializando como um objeto vazio para evitar erros de acesso

    constructor(
        public layoutService: LayoutService,
        private authService: AuthService
    ) {}

    ngOnInit() {
        this.recuperarDadosUsuario();
    }

    recuperarDadosUsuario() {
        const grupoJSON = localStorage.getItem('dadosUser');

        if (grupoJSON == null) {
            const email = this.authService.getEmail();

            this.authService.getUser(email || '').subscribe((rs) => {
                localStorage.setItem('dadosUser', JSON.stringify(rs));
            });
        }

        if (grupoJSON) {
            try {
                this.userDados = JSON.parse(grupoJSON);
                this.criarMenuBase();
                this.adicionarMenusDinamicos();
            } catch (error) {
                console.error('Erro ao parsear dados do usuário:', error);
                this.userDados = {}; // Garante que userDados tenha um valor padrão em caso de erro
            }
        } else {
            console.warn('Nenhum dado do usuário encontrado no localStorage.');
        }
    }

    criarMenuBase() {
        // Define o menu padrão, disponível para todos os usuários
        this.model = [
            {
                label: 'Home',
                items: [
                    {
                        label: 'Dashboard',
                        icon: 'pi pi-fw pi-chart-bar',
                        routerLink: '',
                    },
                ],
            },
            {
                label: 'Links',
                items: [
                    {
                        label: 'SmartQuestion',
                        icon: 'pi pi-fw pi-send',
                        routerLink: '/redirecionamento/',
                    },
                ],
            },
        ];
    }

    adicionarMenusDinamicos() {
        // Verifica se o usuário possui almoxarifados e adiciona dinamicamente
        if (this.userDados?.[0]?.almoxarifados) {
            this.model.push({
                label: 'Almoxarifado',
                items: this.buildDynamicMenu(this.userDados[0].almoxarifados),
            });
        }

        // Adiciona menus específicos para administradores
        if (this.userDados?.[0]?.grupo === 'Adm') {
            this.model.push(
                {
                    label: 'Equipamentos Petrobras',
                    items: [
                        {
                            label: 'Dashboard',
                            icon: 'pi pi-fw pi-chart-bar',
                            routerLink: 'equipamentos-rj/dashboard',
                        },
                        {
                            label: 'Dashboard Previsto x Realizado',
                            icon: 'pi pi-fw pi-chart-line',
                            routerLink:
                                'equipamentos-rj/dashboard/dashboard-previsto-realizado',
                        },
                        {
                            label: 'Inventário',
                            icon: 'pi pi-fw pi-list',
                            routerLink: 'equipamentos-rj/inventario',
                        },
                    ],
                },
                {
                    label: 'Administração',
                    items: [
                        {
                            label: 'Contratos',
                            icon: 'pi pi-globe',
                            routerLink: 'administrativo/contratos',
                        },
                        {
                            label: 'Usuários',
                            icon: 'pi pi-users',
                            routerLink: 'administrativo/usuarios',
                        },
                    ],
                }
            );
        }

        // Adiciona menus específicos para o grupo Petrobras
        if (
            this.userDados?.[0]?.grupo === 'admPetrobrasEquipamentos' ||
            this.userDados?.[0]?.grupo === 'clintePetrobrasEquipamentos'
        ) {
            this.model.push({
                label: 'Equipamentos Petrobras',
                items: [
                    {
                        label: 'Dashboard',
                        icon: 'pi pi-fw pi-chart-bar',
                        routerLink: 'equipamentos-rj/dashboard',
                    },
                    {
                        label: 'Inventário',
                        icon: 'pi pi-fw pi-list',
                        routerLink: 'equipamentos-rj/inventario',
                    },
                ],
            });
        }
    }

    // Função para criar menus dinâmicos com base nos dados de almoxarifados
    buildDynamicMenu(data: DataItem[]): MenuItem[] {
        const menu: MenuItem[] = [];
        const groupedData: Record<string, DataItem[]> = {};

        // Agrupar itens por contrato pai
        data.forEach((item) => {
            const { code, name, contratoPai } = item;
            if (!groupedData[contratoPai || '']) {
                groupedData[contratoPai || ''] = [];
            }
            groupedData[contratoPai || ''].push({ code, name, contratoPai });
        });

        // Criar o menu dinâmico
        for (const key in groupedData) {
            const submenu: MenuItem = {
                label: key || 'Sem contrato pai',
                icon: 'pi pi-fw pi-briefcase',
                items: [],
            };
            groupedData[key].forEach((item) => {
                const rota = `/almoxarifado/${item.code}`;
                submenu.items?.push({
                    label: item.name,
                    routerLink: rota,
                });
            });
            menu.push(submenu);
        }

        return menu;
    }
}
