import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LayoutService } from 'src/app/layout/service/app.layout.service';
import { AuthService } from '../auth.service';
import { Message, MessageService } from 'primeng/api';
import { getAuth, onAuthStateChanged } from "firebase/auth";



@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styles: [`
        :host ::ng-deep .pi-eye,
        :host ::ng-deep .pi-eye-slash {
            transform:scale(1.6);
            margin-right: 1rem;
            color: var(--primary-color) !important;
        }
        .logo{
            width: 50%
        }
        .md\:w-30rem {
            width: 35rem !important;
        }
    `],
    providers: [MessageService]
})
export class LoginComponent implements OnInit {

    msgs: Message[] = [];

    myForm!: FormGroup;

    constructor(
        public layoutService: LayoutService,
        private service: AuthService,
        private formBuilder: FormBuilder,
        private messageService: MessageService
        ) { }

    ngOnInit(){
        this.myForm = this.formBuilder.group({
            email: [null, Validators.required],
            password: [null, Validators.required]
        });
        this.verificarUsurioLogado()
    }

    verificarUsurioLogado(){
        const auth = getAuth();
        onAuthStateChanged(auth, (user) => {
          if (user) {
            this.service.conferirUsuarioLogado(true)
          }
        });
    }

    validador(email:string, senha:string){
        if(email == null || email == '' ){
            this.messageService.add({ severity: 'warn', detail: 'Email Vazio' });
            return true
        }
        if(senha == null || senha == '' ){
            this.messageService.add({ severity: 'warn', detail: 'Senha Vazia' });
            return true
        }
        return false
    }

    salvarDadosUserStorage(email:string){
        this.service.getUser(email).subscribe((rs)=>{
            localStorage.setItem('dadosUser', JSON.stringify(rs))
        })
    }

    onSubmit(){
        var email =  this.myForm.get('email')?.value
        var senha =  this.myForm.get('password')?.value



        if(this.validador(email, senha)) return

        this.service.fazerLogin( email, senha)

        this.service.mostarMenuEmitter.subscribe((rs)=> {
            if(rs){
                this.messageService.add({ severity: 'success', detail: 'Sucesso' });
                this.salvarDadosUserStorage(email)
            }else{
                this.messageService.add({ severity: 'error', summary:'Conta Inválida', detail: 'Confira os dados digitados, se persistir o erro procure o administrador' });
            }
         });
    }


}
