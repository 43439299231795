<div class="layout-footer">
    Create by
    <span class="font-medium ml-2" (click)="dialogSobre = true"
        >Visual Solution</span
    >
</div>

<p-dialog *ngIf="dialogSobre">
    <p-footer>
        <span> Vitor Alves </span>
        <img src="/assets/layout/images/logo-branca.png" alt="logo" />
    </p-footer>
</p-dialog>
