// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {

    //Produção
    // apiKey: "AIzaSyDvlXXP9WmQ1e2RUIgS5ShxrkOaLSWNyDU",
    // authDomain: "visual-cetest.firebaseapp.com",
    // projectId: "visual-cetest",
    // storageBucket: "visual-cetest.appspot.com",
    // messagingSenderId: "513704996811",
    // appId: "1:513704996811:web:03bf89fe6c382c6945d04e"

    //homologação
    apiKey: "AIzaSyB2ac9MGrebeoGif5Y1wpY1NipqWs30_Tg",
    authDomain: "visual-solution-e9a23.firebaseapp.com",
    projectId: "visual-solution-e9a23",
    storageBucket: "visual-solution-e9a23.appspot.com",
    messagingSenderId: "954655697392",
    appId: "1:954655697392:web:60bb47d2adcec658aa9535",
    measurementId: "G-8N3PW8KEEZ"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
